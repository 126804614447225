<template>
  <div class="user page">
    <Nav></Nav>
    <div class="wrap" style="padding-top:10px;min-height: 700px;">
      <a-layout>
        <a-layout-sider>
          <Menu></Menu>
        </a-layout-sider>
        <a-layout>
          <a-layout-header>
            <a-row type="flex" justify="center" align="middle" class="top">
              <a-col :span="6">
                <icon-font type="icon-bonuspoints" />商城订单
              </a-col>
            </a-row>
          </a-layout-header>
          <a-layout-content>
            <div :style="{ minHeight: '440px' }">
              <a-tabs default-active-key="1" @change="tbChange">
                <a-tab-pane v-for="(item, index) in tabs.items" :key="index" :tab="item.name"></a-tab-pane>
              </a-tabs>
              <a-list :loading="loading" item-layout="horizontal" :data-source="data">
                <div v-if="showLoadingMore" slot="loadMore" :style="{
                  textAlign: 'center',
                  marginTop: '12px',
                  height: '32px',
                  lineHeight: '32px',
                }">
                  <a-spin v-if="loadingMore" />
                  <a-button v-else @click="onLoadMore"> loading more </a-button>
                </div>
                <a-list-item slot="renderItem" slot-scope="item">
                  <a-row type="flex" justify="space-between" class="orderitem">
                    <div class="orders">
                      <div class="head">
                        <div class="orderno">订单号码：{{ item.number }}</div>
                        <div class="state">{{ item.state }}</div>
                      </div>
                      <div class="goods">
                        <div class="good" v-for="(itempro, index) in item.products" :key="index">
                          <div class="img">
                            <img :src="itempro.productImgUrl">
                          </div>
                          <div class="info">
                            <div class="goods-name">{{ itempro.productName }}</div>
                            <div class="goods-name">{{ itempro.specName }}</div>
                            <div class="sku-total">￥{{ itempro.productPrice }}x{{ itempro.productCount }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="foot">
                        <div class="price-total">
                          <div class="price">订单金额：￥{{ item.amountPay }}</div>
                        </div>
                        <div class="btn-grp">
                          <a-button type="default" style="width: 120px;" shape="round" size="large"
                            v-if="item.state == '待发货'" @click="cacel(item.number)" block>取消订单</a-button>
                          <a-button type="danger" style="width: 120px;margin-left: 10px;" shape="round" size="large"
                            v-if="item.state == '待付款'" @click="pay(item.number)" block>立即付款</a-button>
                          <a-button type="primary" style="width: 120px;margin-left: 10px;" shape="round" size="large"
                            v-if="item.state == '已发货'" @click="sign(item.number)" block>确认收货</a-button>
                          <a-button type="default" style="width: 120px;margin-left: 10px;" shape="round" size="large"
                            @click="detail(item.number)" block>明细</a-button>
                        </div>
                      </div>
                    </div>
                  </a-row>
                </a-list-item>
              </a-list>
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Foot from "@/components/foot";
import Nav from "@/components/Nav.vue";
import Menu from "../user/components/menu";
import {
  Icon
} from "ant-design-vue";
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2747721_gimu9jlw02u.js",
});
export default {
  components: {
    Nav,
    Foot,
    Menu,
    IconFont,
  },
  data() {
    return {
      user: {},
      data: [],
      tid: 0,
      tabs: {
        items: [],
        current: 0
      },
      loading: true,
      loadingMore: false,
      showLoadingMore: true,
      PageSize: 10,
      pageIndex: 1,
    };
  },
  computed: {},
  created() {
    if (this.$route.name === "Home") this.main_swiper = true;
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "fetchDate",
  },
  mounted() {
    this.user = this.storage.getItem("user");
    if (!this.user) {
      this.getUserInfo();
    }

    this.loadData();
  },
  methods: {
    fetchDate() {
      if (this.$route.name !== "Home") {
        this.main_swiper = false;
      } else {
        this.main_swiper = true;
      }
    },
    getUserInfo() {
      this.api
        .getuserDetail({})
        .then((res) => {
          this.storage.setItem("user", res);
          this.user = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    tbChange(e) {
      this.tabs.current = e;
      this.tid = this.tabs.items[this.tabs.current].id;
      this.pageIndex = 1;
      this.getData((res) => {
        this.loading = false;
        this.data = res;
      });
    },
    loadData() {
      var that = this;
      //订单状态列表
      this.api.getorderStatuslist({}).then(res => {
        this.tabs.items = res;
        this.tid = this.tabs.items[this.tabs.current].id;
        that.getData((res) => {
          that.loading = false;
          that.data = res;
        });
      }).catch(err => {
        console.log(err)
      });
    },
    getData(callback) {
      this.api
        .getorders({
          t: this.tid,
          pageIndex: this.pageIndex - 1,
          pageSize: this.PageSize,
        })
        .then((res) => {
          callback(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onLoadMore() {
      this.loadingMore = true;
      this.pageIndex += 1;
      this.getData((res) => {
        this.data = this.data.concat(res);
        this.loadingMore = false;

        this.$nextTick(() => {
          window.dispatchEvent(new Event("resize"));
        });
      });
    },
    detail(e) {
      this.$router.push("/orderDetail?orderNo=" + e);
    },
    pay(e) {
      this.$router.push("/orderPaymethod?orderNo=" + e);
    },
    sign(e) {
      var that = this;
      this.$confirm({
        title: "提示",
        content: "确认此操作吗?",
        onOk() {
          that.api
            .orderSign({
              orderNo: e,
            })
            .then(() => {
              that.$message.success("签收成功", 10);
              that.getData((res) => {
                that.loading = false;
                that.data = res;
              });
            })
            .catch((err) => {
              console.log(err);
            });
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },
    cacel(e) {
      var that = this;
      this.$confirm({
        title: "提示",
        content: "确认此操作吗?",
        onOk() {
          that.api
            .orderCacel({
              orderNo: e,
            })
            .then(() => {
              that.$message.success("签收成功", 10);
              that.getData((res) => {
                that.loading = false;
                that.data = res;
              });
            })
            .catch((err) => {
              console.log(err);
            });
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
     },
  },
};
</script>


<style lang="less">
@import "~ant-design-vue/dist/antd.less";

.page {
  background: #f5f5f5;
}

.wrap {
  height: 100%;
  // padding-bottom: 20px;
}

.ant-layout {
  height: 100%;
}

.ant-layout-sider,
.ant-layout-content,
.ant-layout-header,
.ant-layout-footer {
  background: #ffffff !important;
  padding: 10px;
}


.ant-layout-sider {
  margin-right: 10px;
  text-align: center;
}

.ant-layout-content {
  margin-top: 10px;
}

.ant-layout-header {
  height: auto;

  .top {
    .ant-col {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .anticon {
      font-size: 24px;
      margin-right: 10px;
    }
  }
}

.user {
  .user-name {
    font-size: 14px;
    margin-top: 10px;
  }

  .other-info {
    text-align: left;
    margin-left: 30px;

    span {
      color: @text-color-secondary;
    }
  }
}

.menu {
  text-align: left;
  padding-left: 30px;
  line-height: 28px;
  margin-top: 30px;
  border-top: 1px solid #e2e2e2;

  dl {
    padding-top: 10px;

    dt {
      font-size: 16px;
      line-height: 28px;
    }

    dd {
      color: @text-color-secondary;
    }
  }
}

div.content-wrap {
  padding: 15px;
}

.ant-list-item {
  padding: 0;
  background: #ffffff;
}

.orderitem {
  width: 100%;
}

.orders {
  width: 100%;
  margin: 10px;

  .head {
    height: 30px;
    line-height: 30px;
    display: flex;
    justify-content: space-between;

    .orderno {
      font-size: 14px;
      font-weight: 600;
      width: 100%;
    }

    .state {
      color: #ff5717;
      width: 100px;
    }
  }

  .goods {
    .good {
      padding: 10px;
      display: flex;
      justify-content: space-between;

      .img {
        width: 150px;
        height: 150px;

        img {
          width: 150px;
          height: 150px;
        }
      }

      .info {
        width: 100%;
        padding-left: 10px;

        .goods-name {
          height: 40px;
          line-height: 40px;
          font-size: 12px;
        }

        .sku-total {
          font-size: 14px;
        }
      }
    }
  }

  .foot {
    text-align: right;
    margin-top: 10px;

    .price-total {
      text-align: right;
      font-size: 12px;

      .price {
        color: #ff5717;
        font-size: 14px;
        font-weight: normal;
      }
    }

    .btn-grp {
      margin-top: 10px;
      text-align: right;

      .u-btn {
        margin-left: 32px;
      }
    }
  }
}
</style>